import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SVGYozobi from '../../svgs/SVGYozobi';

export const BANNER_IMAGE_QUERY = graphql`
  query CoverImage {
    ghostSettings {
      cover_image
    }
  }
`;

const Banner = ({
  subText = undefined,
}: {
  text?: string;
  subText?: string;
}) => {
  const coverImageQuery = useStaticQuery(BANNER_IMAGE_QUERY);
  // eslint-disable-next-line
  const bannerImage = coverImageQuery?.ghostSettings?.cover_image;

  return (
    <div
      className="min-h-banner flex justify-center -mt-48 -mb-hpOverlap bg-no-repeat bg-cover w-full"
      style={{
        ...(bannerImage && {
          backgroundImage: `linear-gradient(180deg, rgba(45, 53, 85, 0.6), rgba(45, 53, 85, 0.6)), url(${bannerImage})`,
        }),
      }}
    >
      <div className="max-w-md w-full mx-auto text-center px-4">
        <div className="flex items-center justify-center  font-normal banner-text-container">
          <h1 className="h1 text-green-500">
            Business & Technology Insights for the Digital Age
          </h1>
        </div>
        {subText && <p className="text-white text-2xl mt-8">{subText}</p>}
      </div>
    </div>
  );
};

export default Banner;
