import React from 'react';
import { graphql } from 'gatsby';

import { Layout, PostCard } from '../components/shared';
import { MetaData } from '../components/shared/meta';
import Stream from '../components/homepage/Stream';
import { IPostNodeProps } from './post';
import { IAuthorProps } from './author';
import Banner from '../components/homepage/Banner';
import { CEO, CTO } from '../utils/authorConfig';

export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    ceoPosts: allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
      filter: { primary_author: { slug: { eq: "dan" } } }
    ) {
      edges {
        node {
          id
          title
          created_at
          reading_time
          slug
          tags {
            id
            name
          }
          primary_tag {
            name
          }
          featureImageSharp {
            childImageSharp {
              full: fluid(maxWidth: 558, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
              thumb: fixed(width: 265, height: 298) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    ctoPosts: allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
      filter: { primary_author: { slug: { eq: "richard" } } }
    ) {
      edges {
        node {
          id
          title
          created_at
          reading_time
          slug
          tags {
            id
            name
          }
          primary_tag {
            name
          }
          featureImageSharp {
            childImageSharp {
              full: fluid(maxWidth: 558, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
              thumb: fixed(width: 265, height: 298) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    cto: ghostAuthor(slug: { eq: "richard" }) {
      id
      name
      slug
      profileImageSharp {
        childImageSharp {
          avatar: fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    ceo: ghostAuthor(slug: { eq: "dan" }) {
      id
      name
      slug
      profileImageSharp {
        childImageSharp {
          avatar: fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;

interface IIndexprops {
  data: {
    ceoPosts: {
      edges: IPostNodeProps[];
    };
    ctoPosts: {
      edges: IPostNodeProps[];
    };
    ceo: IAuthorProps;
    cto: IAuthorProps;
  };
  location: {
    pathname: string;
  };
}

const Index = ({ data, location }: IIndexprops) => {
  const CeoPosts = data?.ceoPosts?.edges || [];
  const CtoPosts = data?.ctoPosts?.edges || [];
  const ceo = data.ceo;
  const cto = data.cto;

  const primaryColour =
    ceo?.slug === CEO
      ? 'border-pink-500 text-pink-500'
      : cto?.slug === CTO
      ? 'border-paleBlue-500 text-paleBlue-500'
      : 'border-white text-white';

  const animationDelayMap: { [key: number]: string } = {
    1: 'animation-delay-1',
    2: 'animation-delay-2',
    3: 'animation-delay-3',
    4: 'animation-delay-4',
    5: 'animation-delay-5',
  };

  return (
    <>
      <MetaData location={location} />
      <Layout margin={false}>
        <Banner />
        <div className="max-w-lg w-full mx-auto">
          <div className="flex w-full justify-between flex-wrap">
            {ceo &&
              CeoPosts.map(({ node }: IPostNodeProps, i: number) => {
                return (
                  <PostCard
                    className={`animated-card
              } ${animationDelayMap[i]}`}
                    primaryColour={primaryColour}
                    tagLimit={1}
                    key={node.id}
                    post={node}
                  />
                );
              })}
          </div>
        </div>
        {/* {ceo && <Stream author={ceo} posts={CeoPosts} />}
          {cto && <Stream author={cto} posts={CtoPosts} />} */}
      </Layout>
    </>
  );
};

export default Index;
